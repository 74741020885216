<template>
    <div class="p-2">
        <div v-if="isLoading">
            <div style="min-height: 300px" class="d-flex align-items-center justify-content-center flex-column">

                <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                <h6 class="text-body mt-3">Loading</h6>

            </div>
        </div>
        <div v-else>

            <div class="d-flex align-items-center mb-2">
                <b-breadcrumb class="mb-0 flex-fill" :items="breadcrumbs"></b-breadcrumb>
                <div class="flex-wrap">

                </div>
            </div>
            <div class="p-2 px-4">
                <h4>Additional features</h4>
                <div class="card border rounded p-3 mt-2">

                    <div class="px-2">
                        <h6 class="mb-2 ">Document checks</h6>
                        <b-form-group
                                label-size="sm">
                            <b-select size="sm" v-model="formData.doc_check_mode"
                                      :options="docCheckOptions"
                                      placeholder=""></b-select>
                            <error-display ident="doc_check_mode" v-model="errors"></error-display>
                        </b-form-group>
                        <h6 class="mb-2 mt-4">Capturing candidate photo</h6>
                        <b-form-group>
                            <b-form-checkbox
                                    size="sm"
                                    v-model="formData.is_photo_required"
                                    :value="1"
                                    unchecked-value="0"
                            >Tick this box to make capturing candidate photo mandatory
                            </b-form-checkbox>
                        </b-form-group>

                        <h6 class="mb-2 mt-4">Application approval</h6>

                        <b-form-group>
                            <b-form-checkbox
                                    size="sm"
                                    v-model="formData.force_flagging"
                                    :value="1"
                                    unchecked-value="0"
                            >Tick this box to only allow supervisors to approve applications
                            </b-form-checkbox>
                        </b-form-group>


                        <div v-if="this.$store.state.user.organisation.flight_path == null || this.$store.state.user.organisation.ip_restriction_enabled === 1">

                            <h6 class="mb-2 mt-4">Login security</h6>
                            <b-form-group>
                                <b-form-checkbox
                                        size="sm"
                                        v-model="formData.is_ip_restricted"
                                        :value="1"
                                        unchecked-value="0"
                                >Tick this box to restrict web portal access to whitelisted IP addresses
                                </b-form-checkbox>
                            </b-form-group>
                            <b-form-group
                                    v-if="formData.is_ip_restricted === 1"
                                    label-size="sm"
                                    label="Please enter IP addresses, hitting the return key to confirm each entry">
                                <b-form-tags placeholder="192.168.0.1" size="sm"
                                             invalid-tag-text="Invalid IP address"
                                             duplicate-tag-text="Duplicate IP address"
                                             v-model="formData.ips"
                                             :tag-validator="ipValidator"></b-form-tags>

                                <error-display ident="ips" v-model="errors"></error-display>
                            </b-form-group>

                        </div>
                    </div>


                    <b-form-group class="mb-0 mt-4">
                        <b-button @click="saveModel" variant="success">Save</b-button>
                    </b-form-group>

                </div>

            </div>
        </div>

    </div>
</template>

<script>

    import {organisationCurrent} from "../../../modules/api/endpoints";
    import {getResource, saveResource} from "../../../modules/api/methods";
    import ErrorDisplay from "../../../components/form_inputs/ErrorDisplay";
    import {containsErrors} from "../../../modules/helpers/helpers";

    export default {
        name: "additional_features",
        components: {ErrorDisplay},
        data: function () {
            return {
                isLoading: false,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: {'name': 'dashboard'}
                    },
                    {
                        text: 'Additional features',
                        active: true
                    }
                ],
                studentOptions: [
                    {value: 'always', text: 'Always ask'},
                    {value: 'never', text: 'Never ask'},
                    {value: 'by_nationality', text: "Don't ask candidates who are British, from Republic of Ireland, Channel Islands, or Isle of Man"},
                ],
                docCheckOptions: [
                    {value: 'on_upload', text: 'Automatically on upload'},
                    {value: 'on_approve', text: 'Automatically on approval'},
                    {value: 'on_request', text: 'All users can request'},
                    {value: 'on_request_supervisor', text: 'Supervisors can request'},
                    {value: 'off', text: 'Off'},
                ],
                studentStatus: [
                    {value: 'both', text: 'Both'},
                    {value: 'students', text: 'Students only'},
                    {value: 'non_students', text: 'Non students'},
                ],
                formData: {},
                errors: {},
            }
        },
        created() {
            this.loadModel()
        },
        watch: {},
        methods: {
            loadModel() {
                this.isLoading = true;
                getResource(organisationCurrent).then((resp) => {
                    var data = resp.data.success.data;
                    if (data.domains !== undefined) {
                        var domains = [];
                        _.each(data.domains, function (obj) {
                            if (obj.domain !== undefined) domains.push(obj.domain);
                        });
                        data.domains = domains;
                    }

                    if (data.ips !== undefined) {
                        var ips = [];
                        _.each(data.ips, function (obj) {
                            if (obj.ip_address !== undefined) ips.push(obj.ip_address);
                        });
                        data.ips = ips;
                    }

                    this.formData = data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            saveModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(organisationCurrent, this.formData).then((resp) => {
                    this.isLoading = false;
                    this.$bvToast.toast('You may need to sign out and back in to see the changes.', {
                        title: 'Settings updated',
                        variant: 'success',
                        solid: false
                    })

                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            },
            ipValidator(tag) {
                return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(tag)
            },
            domainValidator(tag) {
                return /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/.test(tag);
            }
        }

    }
</script>
